import { Button, Card, CardHeader, Container, FormControlLabel, Grid, IconButton, InputAdornment, Modal, Radio, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { updateProfile } from '../volumeActions';

const AddAzureModal = ({ openAzureModal, close, fileStoreType }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  let companyId = localStorage.getItem('companyId');
  const [azureEnableEntraId, setAzureEnableEntraId] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [createData, setCreateData] = useState({
    companyId: +companyId,
    fileStoreBase: fileStoreType,
    name: '',
    azureConnectionString: '',
    awsAccessUrl: '',
    azureAccountName: '',
    azureTenetId: '',
    azureClientSecret: '',
    azureClientId: '',
  });

  const inputHandle = (e) => {
    setCreateData({ ...createData, [e.target.name]: e.target.value });
  };

  const createAzure = () => {
    const { azureConnectionString, azureClientSecret, azureClientId, azureAccountName, azureTenetId, ...rest } = createData;
    const payload = { ...rest }
    if (azureEnableEntraId) {
      payload.azureClientSecret = azureClientSecret;
      payload.azureClientId = azureClientId;
      payload.azureAccountName = azureAccountName;
      payload.azureTenetId = azureTenetId;
      payload.azureEnableEntraId = azureEnableEntraId;
    } else {
      payload.azureConnectionString = azureConnectionString;
      payload.azureEnableEntraId = azureEnableEntraId;
    }
    dispatch(updateProfile(createData));
    close();
  };
  const isButtonDisabled = useMemo(() => {
    if(azureEnableEntraId){
      return !(createData.azureClientSecret && createData.azureClientId && createData.azureAccountName && createData.azureTenetId)
    }else {
      return !(createData.name && createData.azureConnectionString);
    }
  }, [azureEnableEntraId, createData.azureAccountName, createData.azureClientId, createData.azureClientSecret, createData.azureConnectionString, createData.azureTenetId, createData.name])
  return (
    <>
      <Modal
        open={openAzureModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ backgroundColor: '#ffff', pb: '40px' }}>
              <CardHeader title="Azure" />

              <Grid container spacing={3} sx={{ mx: '40px' }}>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Blob Storage Profile Name"
                    variant="standard"
                    name="name"
                    sx={{ width: '100%' }}
                    value={createData.name}
                    onChange={(e) => inputHandle(e)}
                    required
                  />
                </Grid>
                <Grid item mt={2} xs={12} md={12}>
                  <FormControlLabel
                    checked={!azureEnableEntraId}
                    control={<Radio />}
                    label="Connection String"
                    labelPlacement="end"
                    onChange={(event) => setAzureEnableEntraId(false)}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    label="Storage Account Connection String"
                    variant="standard"
                    name="azureConnectionString"
                    sx={{ width: '100%' }}
                    value={createData.azureConnectionString}
                    onChange={(e) => inputHandle(e)}
                    required
                  />
                </Grid>
                <Grid mt={2} item xs={12} md={12}>
                  <FormControlLabel
                    checked={azureEnableEntraId}
                    control={<Radio />}
                    label="Azure EntraID"
                    labelPlacement="end"
                    onChange={(event) => setAzureEnableEntraId(true)}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="azureClientId"
                    label="Azure Client ID"
                    value={createData.azureClientId}
                    onChange={(e) => inputHandle(e)}
                    variant="standard"
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="azureClientSecret"
                    label="Azure Client Secret"
                    value={createData.azureClientSecret}
                    onChange={(e) => inputHandle(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="azureTenetId"
                    label="Azure Tenet ID"
                    value={createData.azureTenetId}
                    onChange={(e) => inputHandle(e)}
                    variant="standard"
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="azureAccountName"
                    label="Azure Account Name"
                    value={createData.azureAccountName}
                    onChange={(e) => inputHandle(e)}
                    variant="standard"
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <Button
                    variant="contained"
                    sx={{ color: '#ffff', ml: '10px' }}
                    onClick={createAzure}
                    disabled={isButtonDisabled}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default AddAzureModal;
