import { ERROR_ALERT, SUCCESS_ALERT, PROGRESS_DONE, PROGRESS_START } from 'src/redux/reducers/alertReducer';
import { deleteInfo, GetDetails, GetFetch, PostDetails, postInfo, putDetails } from 'src/utils/fetchServices';

export const JOB_FETCH_SUCCESS = 'JOB_FETCH_SUCCESS';
export const UNC_PATH_FETCH_SUCCESS = 'UNC_PATH_FETCH_SUCCESS';
export const TIMESTAMP_FETCH_SUCCESS = 'TIMESTAMP_FETCH_SUCCESS';
export const POLICIES_FETCH_SUCCESS = 'POLICIES_FETCH_SUCCESS';
export const EXCLUSION_FETCH_SUCCESS = 'EXCLUSION_FETCH_SUCCESS';
export const AGENTS_FETCH_SUCCESS = 'AGENTS_FETCH_SUCCESS';
export const COPY_AGENTS_FETCH_SUCCESS = 'COPY_AGENTS_FETCH_SUCCESS';
export const JOB_INFO_FETCH_SUCCESS = 'JOB_INFO_FETCH_SUCCESS';
export const FILE_TYPES_FETCH_SUCCESS = 'FILE_TYPES_FETCH_SUCCESS';
export const DELETED_SUCCESSFULLY = 'DELETED_SUCCESSFULLY';
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS';
export const TEST_ACCESS_SUCCESS = 'TEST_ACCESS_SUCCESS';
export const SHARES_FETCH_SUCCESS = 'SHARES_FETCH_SUCCESS';
export const SUCCESS = 'SUCCESS';
export const SHARES_LOADER = 'SHARES_LOADER';
export const DATA_LOADER = 'DATA_LOADER';
export const FETCH_FAIL = 'FETCH_FAIL';
export const STUB_CHARTS_DATA = 'STUB_CHARTS_DATA';
export const FILE_TYPES_DEFAULT_SUCCESS = 'FILE_TYPES_DEFAULT_SUCCESS';

export const getJobs = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  // dispatch({
  //   type: DATA_LOADER,
  // });
  // params.sort = 'id,desc';
  try {
    const response = await GetFetch({ url: `jobs/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: JOB_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getAgents = (params) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: DATA_LOADER,
  });
  params.active = 1;
  try {
    const response = await GetFetch({ url: `agents/company/${companyId}`, params });
    const data = response.data;
    dispatch({
      type: AGENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getCopyAgents = (params) => async (dispatch) => {
  dispatch({
    type: PROGRESS_START,
  });
  try {
    const response = await GetFetch({ url: `jobs/copy`, params });
    const data = response.data;
    dispatch({
      type: COPY_AGENTS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
  dispatch({
    type: PROGRESS_DONE,
  });
};
export const getShares = (params, unc) => async (dispatch) => {
  dispatch({
    type: SHARES_LOADER,
  });
  try {
    const response = await GetFetch({ url: `servers/${unc}/shares`, params });
    const data = response.data;
    dispatch({
      type: SHARES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
    });
    dispatch({
      type: ERROR_ALERT,
      payload: 'failed to connect the server',
    });
  }
};

export const addJob = (body, url, method, navigate) => async (dispatch) => {
  dispatch({ type: DATA_LOADER });
  try {
    const response = await postInfo(
      {
        url: url || `jobs`,
        body,
        method: method || 'POST',
      },
      dispatch
    );
    if (response && response.status === 200) {
      const data = response.data;
      dispatch({
        type: SUCCESS,
        payload: data,
      });
      if (method) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: 'Updated Successfully',
        });
      } else {
        dispatch({
          type: SUCCESS_ALERT,
          payload: 'Added Successfully ',
        });
      }
      navigate('/dashboard/job-list');
    } else {
      dispatch({ type: FETCH_FAIL });
    }
  } catch (error) {
    dispatch({ type: FETCH_FAIL });
  }
};

export const updateJob = (body) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await putDetails({ url: `job`, body });
    const data = response.data;
    dispatch({
      type: JOB_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Job Updated Successfully',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: ERROR_ALERT,
      payload: 'Something went wrong',
    });
  }
};

export const deleteJobStub = (body, navigate) => async (dispatch) => {
  try {
    const response = await PostDetails({ url: `jobs/delete-stub`, body });
    const data = response.data;
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Job Created Successfully',
    });
    navigate('/dashboard/job-list');
  } catch (error) {
    dispatch({
      type: ERROR_ALERT,
      payload: 'Something went wrong',
    });
  }
};

export const testAccess = (body) => async (dispatch) => {
  dispatch({ type: PROGRESS_START });
  try {
    const response = await putDetails({ url: `jobs/test-cifs`, body });
    const data = response.data;
    dispatch({
      type: TEST_ACCESS_SUCCESS,
      payload: data,
    });
    dispatch({ type: PROGRESS_DONE });
  } catch (error) {
    dispatch({ type: PROGRESS_DONE });
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: ERROR_ALERT,
      payload: 'Something went wrong',
    });
  }
};

export const getStubCharts =
  (startDate, endDate, chartType = 'bar') =>
  async (dispatch) => {
    dispatch({ type: DATA_LOADER });

    const companyId = localStorage.getItem('companyId');
    let params = { chartType, startDate, endDate };

    try {
      const response = await GetFetch({ url: `charts/company/${companyId}/files-accessed`, params });
      const data = response.data;
      dispatch({
        type: STUB_CHARTS_DATA,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'Something went wrong',
      });
    }
  };

export const getPolicies = (type) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `policies/company/${companyId}/type/${type}` });
    const data = response.data;
    if (type === 1) {
      dispatch({
        type: POLICIES_FETCH_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: EXCLUSION_FETCH_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getJobInfo = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `jobs/${id}` });
    const data = response.data;
    dispatch({
      type: JOB_INFO_FETCH_SUCCESS,
      payload: data,
    });
    if (response.data && response.data.stagingJob) {
      if (response.data.stagingJob == 1) {
        dispatch(getFileType(4));
      } else {
        dispatch(getFileType(response.data.stagingJob));
      }
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getFileType = (type) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `file-stores/company/${companyId}/base/${type}` });
    const data = response.data;
    dispatch({
      type: FILE_TYPES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getDefaultValue = (type) => async (dispatch) => {
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await GetDetails({ url: `system-codes/global-configuration/company/${companyId}` });
    const data = response.data;
    dispatch({
      type: FILE_TYPES_DEFAULT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};

export const getTimestamp = () => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await GetDetails({ url: `global/api-server-info` });
    const data = response.data;
    dispatch({
      type: TIMESTAMP_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const getUncPath = (companyTag) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    // const response = await GetDetails({ url: `file-stubber/stub-servers/companyTag/DataRepository` });
    const response = await GetDetails({ url: `file-stubber/stub-servers/companyTag/${companyTag && companyTag}` });
    const data = response.data;
    dispatch({
      type: UNC_PATH_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
  }
};
export const deleteJob = (id) => async (dispatch) => {
  dispatch({
    type: DATA_LOADER,
  });
  try {
    const response = await deleteInfo({ url: `jobs/${id}` });
    const data = response.data;
    dispatch({
      type: DELETED_SUCCESSFULLY,
    });
    dispatch({
      type: SUCCESS_ALERT,
      payload: 'Job deleted successfully.',
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAIL,
      payload: error.message,
    });
    dispatch({
      type: ERROR_ALERT,
      payload: 'Delete Failed.',
    });
  }
};
